<template>
	<v-sheet color="transparent">
		<render-content id="aboutusheader"></render-content>
		<v-row>
			<v-col cols="12" md="6">
				<v-text-field
					v-model="user.first_name"
					label="First Name"
					autocomplete="given-name"
				></v-text-field>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					v-model="user.last_name"
					label="Last Name"
					autocomplete="family-name"
				></v-text-field>
			</v-col>
		</v-row>
		<v-text-field
			label="Your Email Address"
			autocomplete="email"
			v-model="user.email"
		></v-text-field>
		<v-text-field
			label="Your Phone Number"
			:value="fixedPhone"
			@input="(v) => (user.phone = v)"
			prefix="+44"
		></v-text-field>

		<p class="text-right">
			<v-btn color="primary" icon @click="update">
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>
		</p>
		<mw-dialog v-model="dialog" submitBtn @submit="updatePhone" eager>
			<template v-slot:dialog-body>
				We need to verify your phone number. Please complete the captcha and
				we will send a code to the provide mobile number by text.
				<div id="recaptcha-container" :class="{ 'd-none': captchad }"></div>
				<v-text-field
					v-model="code"
					label="Code"
					v-if="captchad"
				></v-text-field>
			</template>
		</mw-dialog>
		<v-snackbar v-model="saved">Profile updated</v-snackbar>
		<v-alert type="error" text v-if="error">
			{{ error }}
		</v-alert>
	</v-sheet>
</template>

<script>
import firebase from "firebase";
import MwDialog from "@c/ui/MwDialog.vue";
import RenderContent from "@c/ui/RenderContent.vue";
export default {
	name: "Profile",
	data: () => {
		return {
			error: false,
			saved: false,
			dialog: false,
			code: null,
			verification_id: null,
			captchad: false,
		};
	},
	components: {
		MwDialog,
		RenderContent
	},
	computed: {
		user() {
			return this.$store.state.auth.data;
		},
		fixedPhone() {
			return this.mwutils.fixPhone(this.user.phone);
		},
		savePhone() {
			return "+44" + this.fixedPhone;
		},
	},
	methods: {
		update() {
			const self = this;
			let fn = self.user.first_name || "";
			let ln = self.user.last_name || ""
			let name = `${fn} ${ln}`
			self.$store
				.dispatch("auth/patch", {
					name: name,
					email: self.user.email,
					phone: self.savePhone,
					first_name: fn,
					last_name: ln,
				})
				.then(() => {
					if (firebase.auth().currentUser.displayName !== name) {
						firebase
							.auth()
							.currentUser.updateProfile({
								displayName: name,
							})
							.catch((e) => {
								self.error = e.message;
							});
					}
					if (firebase.auth().currentUser.email !== self.user.email) {
						firebase
							.auth()
							.currentUser.updateEmail(self.user.email)
							.catch((e) => {
								self.error = e.message;
							});
					}
					if (firebase.auth().currentUser.phoneNumber !== self.savePhone) {
						self.dialog = true;
						let captcha = new firebase.auth.RecaptchaVerifier(
							"recaptcha-container"
						);

						var provider = new firebase.auth.PhoneAuthProvider();
						provider
							.verifyPhoneNumber(self.savePhone, captcha)
							.then((verificationId) => {
								self.captchad = true;
								self.verification_id = verificationId;
							});
					} else {
						self.saved = true;
					}
				});
		},
		parseEmail(r) {
			return r.toLowerCase().trim();
		},
		async verifyCode() {
			const self = this;
			return firebase.auth.PhoneAuthProvider.credential(
				self.verification_id,
				self.code
			);
		},
		updatePhone() {
			const self = this;
			if (self.code) {
				self.verifyCode().then((phoneCredential) => {
					self.saved = true;
					return firebase
						.auth()
						.currentUser.updatePhoneNumber(phoneCredential);
				});
			}
		},
	},
};
</script>
